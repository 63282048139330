// import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "../styles/fonts.scss";
import "../styles/globals.scss";

import Error from "next/error";
import nextCookie from "next-cookies";
import { appWithTranslation } from "next-i18next";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { Api } from "../networks/Api";
import axios from "axios";
import { destroyCookie } from "nookies";
import redirectTo from "../components/RedirectTo";

const App = ({
  Component,
  pageProps,
  categoryMenu,
  hasSale,
  hasLive,
  dataSeo,
  dataReferrer,
}) => {
  if (pageProps?.error) {
    return (
      <Error
        statusCode={pageProps.error.statusCode}
        title={pageProps.error.message}
      />
    );
  }

  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Prompt",
          },
        }}
      >
        {/* เอาอันนี้ออกแล้วหน้าเว็บจะไม่ขึ้น progress bar */}
        {/* <NextNProgress /> */}
        <Component
          {...pageProps}
          categoryMenu={categoryMenu}
          hasSale={hasSale}
          hasLive={hasLive}
          dataSeo={dataSeo}
          dataReferrer={dataReferrer}
        />
      </ConfigProvider>
    </Provider>
  );
};

App.getInitialProps = async ({ Component, ctx }) => {
  const c = nextCookie(ctx);
  // const noAuth = ["/login", "/signup", "/demo"];
  const { id, category } = ctx.query; // id คือ query string parameter ที่ส่งมา
  const pathname = ctx.pathname; // ดึง route pathname// ตรวจสอบว่ามาจากเซิร์ฟเวอร์หรือไม่
  const dataReferrer = ctx.req?.headers?.referer || ""; // ดึงค่า referrer จาก headers

  let pageProps = {};
  let categoryMenu, hasSale, hasLive, dataSeo, profileData;

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  try {
    const url = `${Api.getGroupCategory}`;
    const response = await axios.get(url, Api.Header());
    if (response?.status == 200) {
      const cate = response?.data?.data;
      categoryMenu = cate;
    }

    const url_has_sale = `${Api.checkHasSaleProduct}`;
    const response_has_sale = await axios.get(url_has_sale, Api.Header());
    if (response_has_sale?.status == 200) {
      hasSale = response_has_sale?.data?.has_sale_product;
    }

    const url_has_live = `${Api.checkHasLiveProduct}`;
    const response_has_live = await axios.get(url_has_live, Api.Header());
    if (response_has_live?.status == 200) {
      hasLive = response_has_live?.data?.has_sale_product;
    }

    let urlSeo = Api.getPageSEOUrl;
    let postData = {
      url: pathname,
    };

    // กรณีเปิดหน้ารายละเอียดสินค้า
    if (id) {
      urlSeo = Api.getPageSEOProduct;
      postData = {
        product_id: id,
      };
    }

    // กรณีเปิดหน้ารวมสินค้า
    if (category) {
      urlSeo = Api.getPageSEOBrand;
      postData = {
        brand_id: category,
      };
    }

    let response_article, response_seo;
    response_seo = await axios.post(urlSeo, postData, Api.Header());

    if (category) {
      const url_article = `${Api.getFeedArticleByBrandWithoutHtml}`;
      response_article = await axios.post(
        url_article,
        {
          brand_name: category,
        },
        Api.Header()
      );
    }
    dataSeo = response_article
      ? response_article?.data?.data
      : response_seo?.data?.data;

    const url_profile = `${Api.UserProfile}`;
    const response_profile = await axios.get(url_profile, Api.Header(c.token));
    if (response_profile?.status == 200) {
      profileData = response_profile?.data?.data;
    } else {
    }
  } catch (e) {
    // console.log("fetchData >> getGroupCategory error :", e);
  }

  if (c.token == "undefined" || c.token == null) {
    return {
      pageProps,
      categoryMenu,
      hasSale,
      hasLive,
      dataSeo,
      dataReferrer,
    };
  } else {
    let token = c.token;
    try {
      const url = `${Api.UserProfile}`;
      const response = await axios.get(url, Api.Header(token));
      if (response.data) {
      } else {
        destroyCookie(ctx, "token");
        redirectTo("/signup", { res: ctx.res, status: 301 });
      }
    } catch (error) {
      destroyCookie(ctx, "token");
      redirectTo("/signup", { res: ctx.res, status: 301 });
    }

    return {
      ...pageProps,
      ...{ query: ctx.query },
      categoryMenu,
      hasSale,
      hasLive,
      dataSeo,
      dataReferrer,
    };
  }
};

export default appWithTranslation(App);
